<template>
  <div class="user fx-row">
    <div class="user-info fx-row">
      <el-avatar :size="40" shape="circle"
        src="https://cube.elemecdn.com/3/7c/3ea6beec64369c2642b92c6726f1epng.png"></el-avatar>
      <div class="user-info-name ft-sz-18 ft-bold">
        <span>你好， {{ account.name }}</span>
        <p>{{ account.mobile }}</p>
      </div>
    </div>
    <div class="path">
      <div class="path-title">
        <i class="el-icon-menu"></i>
        <span>{{ breadList[breadList.length - 1].name || '总后台' }}</span>
      </div>
      <el-breadcrumb separator-class="el-icon-arrow-right" class="bread">
        <el-breadcrumb-item v-for="(item, index) in breadList" :key="index" :to="{ path: item.path }">{{ item.name
          }}</el-breadcrumb-item>
      </el-breadcrumb>
    </div>
  </div>
</template>

<script>
export default {
  computed: {
    account () {
      let user = this.$store.state.XINYOU_ADMIN_USER || {};
      return {
        name: user.account || '',
        mobile: user.mobile || '********'
      };
    },
    // 面包屑导航
    breadList () {
      let list = [{ path: '/', name: '总后台' }].concat(this.$route.meta.bread);
      return this.$route.meta.bread ? list : [];
    }
  }
};
</script>

<style lang="scss">
.user {
  height: 160px;
  padding: 0 2%;
  padding-top: 40px;
  background-color: #2b65b1;

  &-info {
    width: 270px;
    margin-right: 20px;
    text-align: left;

    &-name {
      color: #fff;
      margin-left: 15px;
      white-space: nowrap;
      max-width: 210px;
      overflow: auto;
    }
  }

  .path {
    margin-left: 250px;
    color: #fff;
    position: absolute;

    &-title {
      font-size: 20px;
      font-weight: 500;
      text-align: left;
    }

    .bread {
      margin-top: 10px;

      .el-breadcrumb__inner,
      .el-breadcrumb__inner:hover {
        color: #fff;
        font-weight: 500;
        font-size: 15px;
        cursor: pointer;
      }
    }
  }
}

@media screen and (max-width: 480px) {
    .user {
        height: 60px;
        width: 100%;
        background-color: none;
    }
    .user-info {
        display: none;
    }
    .user .path{
      display: none;
    }
}

</style>
